.header-container {
  background: #fff;
  border-bottom: 1px solid var(--lighter-gray);
  box-shadow: 0 1px 10px rgb(151 164 175 / 10%);
}

.header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
