.pools-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.pools-list {
  overflow-y: auto;
}

.pools-list > table {
  border-spacing: 0;
  width: 100%;
}

.pools-list > table > thead {
  background: var(--lighter-gray);
  position: sticky;
  top: 0;
}

.pools-list > table > thead th {
  padding: 10px;
}

.pools-list > table > thead > tr:last-child > th {
  padding-top: 0;
}

.pools-list > table > tbody tr td {
  padding: 10px;
}
.pools-list > table > tbody tr.row-detail td {
  padding: 0 10px;
}

.pools-list > table > tbody tr.inactive td {
  color: var(--light-gray);
}

.pools-list > table > tbody tr.even td {
  background: var(--lightest-gray);
}

.pools-manage > * {
  margin-right: 3px;
}
.pools-manage > *:last-child {
  margin-right: 0;
}

.pools-inactive {
  padding-top: 20px;
}
.pools-inactive label {
  cursor: pointer;
}

.stakes-cell {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.stakes-progress {
  height: 5px;
  grid-column-start: 1;
  grid-column-end: 3;
}

.pool-detail {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  gap: 10px;
}

.actions {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.pool-validator {
  color: var(--green);
  font-size: 18px;
  display: inline-block;
  padding-left: 10px;
  user-select: none;
}
