.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -65px;
  display: inline-block;
  z-index: 1000;
  color: #fff;
  width: 130px;
  visibility: hidden;
  white-space: normal;
}
.tooltip.bottom {
  top: 100%;
}

.tooltip-trigger {
  position: relative;
}

.tooltip-trigger:hover .tooltip {
  visibility: visible;
}

.tooltip > div {
  border-radius: 15px;
  padding: 5px 10px;
  background: var(--dark-gray);
}

.tooltip:after {
  display: block;
  content: "";
  border-top: 5px solid var(--dark-gray);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  padding-bottom: 5px;
  width: 0;
  margin: 0 auto;
}
.tooltip.bottom:after {
  display: none;
}
.tooltip.bottom:before {
  display: block;
  content: "";
  border-bottom: 5px solid var(--dark-gray);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  padding-top: 5px;
  width: 0;
  margin: 0 auto;
}
