.progress-wrapper {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.progress {
  height: 50px;
}

.progress-top {
  position: relative;
  width: 0;
  border-top: 8px solid gray;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.progress-bottom {
  position: relative;
  width: 0;
  border-bottom: 8px solid gray;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.progress-text {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.staking-allowed {
  font-size: larger;
  font-weight: bold;
  color: #6f996b;
}

.staking-disallowed {
  font-size: larger;
  font-weight: bold;
  color: #996b6b;
}
