.modal-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: #fff;
  width: 100%;
  max-width: 500px;
  padding: 20px 25px;
  border-radius: 20px;
  margin: 20px;
}

.modal-title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  font-size: 18px;
}

.modal-close .icon {
  cursor: pointer;
  transition: all 0.4s ease;
}
.modal-close .icon:hover {
  transform: rotate(90deg);
}
