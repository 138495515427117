.input-text {
  display: block;
  padding-bottom: 20px;
}

.input-text input {
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  padding: 10px;
  border: 1px solid var(--red);
}
.input-text input.input-valid:focus,
.input-text input.input-valid {
  border-color: var(--green);
}
.input-text input.input-valid:focus:disabled,
.input-text input.input-valid:disabled,
.input-text input:disabled {
  border: 1px solid var(--light-gray);
}
.input-text input:focus,
select:focus {
  outline: none;
}

.input-text-wrapper {
  position: relative;
}

.input-text-unit {
  position: absolute;
  right: 10px;
  top: 10px;
}

.input-text-error {
  color: var(--red);
}
