.button {
  font-family: inherit;
  font-size: inherit;
  padding: 15px 30px;
  border: 0;
  border-radius: 100px;
  background: var(--green);
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
}
.button.full {
  width: 100%;
}

.button:disabled {
  background: var(--light-gray);
  cursor: not-allowed;
}

.button.small {
  padding: 6px 15px;
}
