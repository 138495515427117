:root {
  --green: #3aac59;
  --lightest-gray: #f2f2f2;
  --lighter-gray: #e5e5e5;
  --light-gray: #cccccc;
  --dark-gray: #4a4f55;
  --red: #ff0000;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  color: var(--dark-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
  background: var(--lightest-gray);
}

h1 {
  font-size: 21px;
  font-weight: 400;
  margin: 0;
}

table {
  border-spacing: 0;
}

select {
  border: 1px solid var(--green);
  padding: 5px 10px;
  border-radius: 100px;
  background: transparent;
  cursor: pointer;
}

iframe {
  display: none;
}

.bold {
  font-weight: bold;
}

.nowrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
